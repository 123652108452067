<template>
  <div class="multi-step-form h-screen">
    <div class="flex h-screen">
      <div class="hidden md:block bg-[#f2f5f7] w-6/12">
        <div class="flex flex-col justify-between h-4/6">
          <div class="text-center mb-8 mt-32">
            <img src="@/assets/logo.png" alt="Logo Krits" width="250" class="inline-block">
          </div>
          <h1 class="p-8 headline text-5xl font-bold">{{ currentStep.headline }}</h1>
          <div class="px-12">
            <progress-bar :progress="progress"></progress-bar>
          </div>
        </div>
      </div>
      <div class=" w-full h-screen flex flex-col justify-center items-center">
        <div class="container mx-auto md:px-[30px] 2xl:px-32">
          <component :is="currentStep.component" :formData="formData[currentStepIndex]" @update="updateFormData" @next="nextStep" @prev="prevStep" ref="currentStepComponent"></component>
          <div class="navigation mt-28 flex justify-center">
            <button class="text-kritsBlack bg-kritsYellow hover:bg-lime-800 hover:text-white focus:ring-4 focus:outline-none font-semibold rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mx-2" @click="prevStep" v-if="!isFirstStep">
              <svg class="rotate-180 w-3.5 h-3.5 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
              </svg>
              Vorige
            </button>
            <button type="button" class="text-kritsBlack bg-kritsYellow hover:bg-lime-800 hover:text-white focus:ring-4 focus:outline-none font-semibold rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mx-2" @click="nextStep" v-if="!isLastStep" :disabled="!isStepCompleted">
              Volgende
              <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
              </svg>
            </button>
            <button type="button" class="text-kritsBlack bg-green-800 hover:bg-lime-800 hover:text-white focus:ring-4 focus:outline-none font-semibold rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mx-2" @click="handleSubmit" v-if="isLastStep">
              Prijs aanvragen
              <i class="fa-regular fa-thumbs-up ms-2"></i>
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import ProgressBar from './ProgressBar.vue';
import Step1 from './StepOne.vue';
import Step2 from './StepTwo.vue';
import Step3 from './StepThree.vue';
import Step4 from './StepFour.vue';
import Step6 from './StepSix.vue';

export default {
  components: { ProgressBar, Step1, Step2, Step3, Step4, Step6 },
  data() {
    return {
      currentStepIndex: 0,
      steps: [
        { component: Step1, headline: 'Krits Calculator' },
        { component: Step2, headline: 'Integraties' },
        { component: Step3, headline: 'Integraties' },
        { component: Step4, headline: 'Apparaten' },
        { component: Step6, headline: 'Prijsberekening' },
      ],
      formData: {
        0: {},
        1: {},
        2: {},
        3: {},
        4: {},
        5: {},
      },
    };
  },
  computed: {
    currentStep() {
      return this.steps[this.currentStepIndex];
    },
    isFirstStep() {
      return this.currentStepIndex === 0;
    },
    isLastStep() {
      return this.currentStepIndex === this.steps.length - 1;
    },
    progress() {
      return ((this.currentStepIndex + 1) / this.steps.length) * 100;
    },
    isStepCompleted() {
      const currentData = this.formData[this.currentStepIndex];
      if (!currentData) return false;
      return Object.values(currentData).some(value => value);
    },
  },
  methods: {
    nextStep() {
      if (!this.isLastStep && this.isStepCompleted) {
        if (this.currentStepIndex === 1) { // StepTwo
          const selectedOption = this.formData[1].selectedOption;
          if (selectedOption === 'Ja') {
            this.currentStepIndex = 2; // StepThree
          } else {
            this.currentStepIndex = 3; // StepFour
          }
        }
        else if(this.currentStepIndex === 0) { // StepTwo
          const selectedOption = this.formData[0].selectedOption;
          if (selectedOption === 'Extra functionaliteiten voor huidige software') {
            this.currentStepIndex = 2; // StepThree
          } else {
            this.currentStepIndex++;
          }
        }
        else {
          this.currentStepIndex++;
        }
      }
    },
    prevStep() {
      if (!this.isFirstStep) {
        this.currentStepIndex--;
      }
    },
    updateFormData(data) {
      this.formData[this.currentStepIndex] = { ...this.formData[this.currentStepIndex], ...data };
    },
    formatFormData() {
      const formattedData = `
        Waar heb je een applicatie voor nodig?: ${this.formData[0].selectedOption || 'Niet beantwoord'}
        Wens je een integratie in je applicatie?:  ${this.formData[1] ? this.formData[1].selectedOption || 'Niet beantwoord' : 'Niet beantwoord'}
        Welke integraties moeten we voorzien?: ${this.formData[2] ? this.formData[2].selectedOptions || 'Niet beantwoord' : 'Niet beantwoord'}
        Andere integratie? : ${this.formData[2] ? this.formData[2].customOptionSelected || 'Niet beantwoord' : 'Niet beantwoord'}
        Welke integratie? : ${this.formData[2] ? this.formData[2].otherApplication || 'Niet beantwoord' : 'Niet beantwoord'}


        Welke apparaten zal je app ondersteunen? : ${this.formData[3] ? this.formData[3].selectedOptions || 'Niet beantwoord' : 'Niet beantwoord'}
        'Naam :  ${this.formData[5] ? this.formData[5].name || 'Niet beantwoord' : 'Niet beantwoord'}
        'E-mail :  ${this.formData[5] ? this.formData[5].email || 'Niet beantwoord' : 'Niet beantwoord'}
        'Telefoon :  ${this.formData[5] ? this.formData[5].phone || 'Niet beantwoord' : 'Niet beantwoord'}
        'Idee :  ${this.formData[5] ? this.formData[5].idea || 'Niet beantwoord' : 'Niet beantwoord'}
      `;
      return formattedData;
    },
    handleSubmit() {
      const stepSixComponent = this.$refs.currentStepComponent;
      if (stepSixComponent && stepSixComponent.validateForm()) {
        const emailData = {
          to: 'info@krits.be',
          subject: 'Aanvraag Calculator Krits',
          text: this.formatFormData(),
        };

        axios.post('https://kritsnode.l27powered.eu/send-email', emailData)
            .then(response => {
              console.log('SUCCESS!', response.data);
              //window.location.replace("https://lp.krits.be/kennismaking");
            })
            .catch(error => {
              console.error('FAILED...', error);
              alert('Form submission failed. Please try again.');
            });
      }
    }
  }
};
</script>

<style>
* {
  font-family: "overpass", sans-serif;
}
.logo {
  position: absolute;
  top: 10px;
  left: 10px;
}

.headline {
  margin-bottom: 20px;
}

.navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
div:not(.stepThree).icons-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

div:not(.stepThree).icons {
  display: flex;
  justify-content: space-around;
  gap: 20px; /* Add space between icons */
  flex-wrap: wrap;
  max-width: 1000px; /* Make the container wider */
}

div:not(.stepThree).icon {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  width: 300px; /* Adjust width to make icons more spread out */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Add space between icon and text */
  background-color: #FAFBFC;
}

div:not(.stepThree).icon:hover {
  background-color: #f0f0f0;
  border-color: #bbb;
}

div:not(.stepThree).icon.active {
  background-color: rgba(231, 253, 87);
  border-color: rgba(39, 39, 38, 0.39);
  color: #272726;
}

div:not(.stepThree).icon-image {
  font-size: 40px;
  margin-bottom: 10px;
  color: inherit; /* Inherit color for active state */
}

div:not(.stepThree).icon span {
  font-size: 16px;
  color: inherit; /* Inherit color for active state */
}
</style>
