<template>
  <div class="stepSix">
    <h2 class="mb-6 pb-6 inline-block text-3xl font-bold">Vul hier je gegevens in zodat we de prijsaanvraag kunnen versturen</h2>
    <div class="form-group">
      <label for="name">Naam</label>
      <input id="name" v-model="localFormData.name" type="text" class="form-control" placeholder="Naam">
      <span v-if="!localFormData.name && showError" class="text-red-500">Gelieve dit veld in te vullen</span>
    </div>
    <div class="form-group">
      <label for="email">E-mailadres</label>
      <input id="email" v-model="localFormData.email" type="email" class="form-control" placeholder="E-mailadres">
      <span v-if="!localFormData.email && showError" class="text-red-500">Gelieve dit veld in te vullen</span>
    </div>
    <div class="form-group">
      <label for="phone">Telefoonnummer</label>
      <input id="phone" v-model="localFormData.phone" type="tel" class="form-control" placeholder="Telefoonnummer">
      <span v-if="!localFormData.phone && showError" class="text-red-500">Gelieve dit veld in te vullen</span>
    </div>
    <div class="form-group">
      <label for="idea">Project</label>
      <textarea id="idea" v-model="localFormData.idea" class="form-control" placeholder="Geef een korte omschrijving van wat jouw project juist inhoud" rows="5"></textarea>
      <span v-if="!localFormData.idea && showError" class="text-red-500">Gelieve dit veld in te vullen</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepSix',
  props: {
    formData: {
      type: Object,
      default: () => ({
        name: '',
        email: '',
        phone: '',
        idea: ''
      })
    }
  },
  data() {
    return {
      localFormData: { ...this.formData },
      showError: false
    };
  },
  watch: {
    localFormData: {
      handler(newVal) {
        this.$emit('update', { ...newVal });
      },
      deep: true
    },
    formData: {
      handler(newVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(this.localFormData)) {
          this.localFormData = { ...newVal };
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    validateForm() {
      this.showError = true;
      return this.localFormData.name && this.localFormData.email && this.localFormData.phone && this.localFormData.idea;
    }
  }
};
</script>

<style>
.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 5px;
}

textarea.form-control {
  resize: vertical;
}

.text-red-500 {
  color: #f56565;
  font-size: 0.875em;
}

@media (max-width: 767px) {
  .stepSix {
    padding: 0 20px;
  }
  .stepSix h2 {
    font-size: 24px;
  }
}
</style>
