<template>
  <div class="stepThree">
    <h2 class="mb-6 pb-6 inline-block text-3xl font-bold">Welke integraties moeten we voorzien?</h2>
    <div class="grid grid-cols-3 gap-4">
      <div v-for="option in options" :key="option.name" class="relative p-4 border rounded cursor-pointer flex justify-center items-center" @click="toggleSelection(option.name)" :class="{ 'bg-green-100': isSelected(option.name) }">
        <img :src="require(`@/assets/integraties/${option.image}`)" :height="option.height" :alt="option.name" class="logo-image mx-auto">
        <div v-if="isSelected(option.name)" class="absolute top-0 right-0 p-2 text-green-600">
          <i class="fas fa-check-circle"></i>
        </div>
      </div>
    </div>
    <div class="mt-8">
      <label class="flex items-center">
        <input type="checkbox" v-model="customOptionSelected" @change="toggleCustomOption" class="mr-2">
        Mijn gewenste software staat niet in deze lijst
      </label>
      <div v-if="customOptionSelected" class="mt-2">
        <input type="text" v-model="otherApplication" @change="toggleCustomOption" placeholder="Voer de applicatie(s) in die je wil integreren" class="w-full p-2 border rounded">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepThree',
  props: {
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      options: [
        { name: 'Odoo', image: 'odoo_logo.svg', height: "60"},
        { name: 'Exact online', image: 'exactOnline_logo.png' },
        { name: 'Teamleader', image: 'teamleader_logo.png' },
        { name: 'Wolter Kluwer', image: 'woltersKluwer_logo.png' },
        { name: 'Yuki', image: 'yuki_logo.svg' },
        { name: 'hubspot', image: 'hubspot_logo.png' },
        { name: 'Microsoft', image: 'microsoft_logo.png' },
        { name: 'Salesforce', image: 'salesforce_logo.png' },
        { name: 'Onlinefact', image: 'onlinefact_logo.webp' },
      ],
      selectedOptions: this.formData.selectedOptions || [],
      customOptionSelected: this.formData.customOptionSelected || false,
      otherApplication: this.formData.otherApplication || ''
    };
  },
  methods: {
    toggleSelection(option) {
      const index = this.selectedOptions.indexOf(option);
      if (index === -1) {
        this.selectedOptions.push(option);
      } else {
        this.selectedOptions.splice(index, 1);
      }
      this.updateFormData();
    },
    toggleCustomOption() {
      if (!this.customOptionSelected) {
        this.otherApplication = '';
      }
      this.updateFormData();
    },
    isSelected(option) {
      return this.selectedOptions.includes(option);
    },
    updateFormData() {
      this.$emit('update', {
        selectedOptions: this.selectedOptions,
        customOptionSelected: this.customOptionSelected,
        otherApplication: this.otherApplication
      });
    }
  },
  watch: {
    formData: {
      handler(newVal) {
        if (newVal) {
          this.selectedOptions = newVal.selectedOptions || [];
          this.customOptionSelected = newVal.customOptionSelected || false;
          this.otherApplication = newVal.otherApplication || '';
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style>
.logo-image {
  width: 150px;
  height: auto;
}

@media (max-width: 767px) {
  .stepThree {
    padding: 0 20px;
  }
  .stepThree h2 {
    font-size: 24px;
  }
}
</style>
