<template>
  <div>
    <h2 class="mb-6 pb-6 inline-block text-3xl font-bold">Welke apparaten zal je app ondersteunen?</h2>
    <div class="icons">
      <div v-for="option in options" :key="option" class="icon" @click="toggleOption(option.name)" :class="{ 'active': isSelected(option.name) }">
        <i :class="option.icon" class="icon-image"></i>
        <span>{{ option.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepFour',
  props: {
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      options: [
        { name: 'Telefoon', label: 'Telefoon', icon: 'fas fa-mobile-screen-button' },
        { name: 'Tablet', label: 'Tablet', icon: 'fas fa-tablet-screen-button' },
        { name: 'Desktop', label: 'Desktop', icon: 'fas fa-desktop' },
      ],
      selectedOptions: this.formData.selectedOptions || [],
    };
  },
  methods: {
    toggleOption(option) {
      const index = this.selectedOptions.indexOf(option);
      if (index === -1) {
        this.selectedOptions.push(option);
      } else {
        this.selectedOptions.splice(index, 1);
      }
      this.$emit('update', { selectedOptions: this.selectedOptions });
    },
    isSelected(option) {
      return this.selectedOptions.includes(option);
    }
  },
  watch: {
    formData: {
      handler(newVal) {
        if (newVal && newVal.selectedOptions) {
          this.selectedOptions = newVal.selectedOptions;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
