<template>
  <div>
    <h2 class="mb-6 pb-6 inline-block text-3xl font-bold">Wens je een integratie in je applicatie?</h2>
    <div class="icons">
      <div v-for="option in options" :key="option" class="icon" @click="selectOption(option.name)" :class="{ 'active': selectedOption === option.name }">
        <i :class="option.icon" class="icon-image"></i>
        <span>{{ option.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepTwo',
  props: {
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      options: [
        { name: 'Ja', label: 'Ja', icon: 'fas fa-check' },
        { name: 'Nee', label: 'Nee', icon: 'fas fa-times' },
        { name: 'Geen idee', label: 'Geen idee', icon: 'fas fa-question' },
      ],
      selectedOption: this.formData.selectedOption || null,
    };
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
      this.$emit('update', { selectedOption: option });
      this.$emit('next');
    },
  },
  watch: {
    formData: {
      handler(newVal) {
        if (newVal && newVal.selectedOption) {
          this.selectedOption = newVal.selectedOption;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped>
.icons {
  display: flex;
  justify-content: space-around;
}

.icon {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  width: 150px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #FAFBFC;
}

.icon:hover {
  background-color: #f0f0f0;
  border-color: #bbb;
}

.icon.active {
  background-color: #4caf50;
  border-color: #4caf50;
  color: white;
}

.icon-image {
  font-size: 40px;
  margin-bottom: 10px;
  color: inherit;
}

.icon span {
  font-size: 16px;
  color: inherit;
}
</style>
