<template>
  <div>
    <h2 class="mb-6 pb-6 inline-block text-3xl font-bold">Waar heb je een applicatie voor nodig?</h2>
    <div class="icons-container">
      <div class="icons">
        <div v-for="option in options" :key="option.name" class="icon" @click="selectOption(option)" :class="{ 'active': isSelected(option.name) }">
          <i :class="option.icon" class="icon-image"></i>
          <span>{{ option.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepOne',
  props: {
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      options: [
        { name: 'Software voor CRM / ERP / planning / facturatie / stock', label: 'Software voor CRM / ERP / planning / facturatie / stock', icon: 'fas fa-briefcase' },
        { name: 'Extra functionaliteiten voor huidige software', label: 'Extra functionaliteiten voor huidige software', icon: 'fas fa-tools' },
        { name: 'Ik wil iets compleet nieuw laten bouwen', label: 'Ik wil iets compleet nieuw laten bouwen', icon: 'fas fa-lightbulb' },
      ],
      selectedOption: this.formData.selectedOption || null,
    };
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option.name;
      this.$emit('update', { selectedOption: option.name });
      this.$emit('next');
    },
    isSelected(option) {
      return this.selectedOption === option;
    }
  },
  watch: {
    formData: {
      handler(newVal) {
        if (newVal && newVal.selectedOption) {
          this.selectedOption = newVal.selectedOption;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style>
.icons-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

.icons {
  display: flex;
  justify-content: space-around;
  gap: 20px; /* Add space between icons */
  flex-wrap: wrap;
  max-width: 1000px; /* Make the container wider */
}

.icon {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  width: 300px; /* Adjust width to make icons more spread out */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Add space between icon and text */
  background-color: #FAFBFC;
}

.icon:hover {
  background-color: #f0f0f0;
  border-color: #bbb;
}

.icon.active {
  background-color: rgba(231, 253, 87);
  border-color: rgba(39, 39, 38, 0.39);
  color: #272726;
}

.icon-image {
  font-size: 40px;
  margin-bottom: 10px;
  color: inherit; /* Inherit color for active state */
}

.icon span {
  font-size: 16px;
  color: inherit; /* Inherit color for active state */
}
</style>
